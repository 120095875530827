import {get, post, request, download, del} from "@/service/index.js";

/*  */
export const getList = (data) => {
    return post("/system/oprt/log/list", data).then((res) => res);
};

export const getDetail = (data) => {
    return get("/system/oprt/log/detail/"+ data).then((res) => res);
};

export const ReqLogsExport = (data) => {
    return download("/system/oprt/log/exportOprtLogExcel", data).then(res => res);
};

export const deleteOprtLogs = (data) => {
    return del('/system/oprt/log', data).then(res => res)
}

export const cleanOprtLogs = (data) => {
    return del('/system/oprt/log/clean', data).then(res => res)
}
